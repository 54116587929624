import React,{ useState, useEffect } from "react"
import { Timmi } from "../components/timmi"
import "../components/timmi.css"
// import { NavHeader } from "../components/navigation"
import ArrowDown from "../components/arrowDown"
import { scroller } from "react-scroll"


export default function Home() {
  function scrollToPortfolio() {
    scroller.scrollTo("portfolio", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
      containerId: 'portfolio'
    })
  }
  return (
    <section className="timmiPage" id="home">
      <div className="logo">
        <Timmi></Timmi>
        <br />
        <ArrowDown text="more" more="introduction"></ArrowDown>
      </div>
    </section>
  )
}
