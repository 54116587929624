import React from "react"
import arrowDown from "../images/arrow.svg"
import "./timmi.css"
import { Link } from "react-scroll"
export default function ArrowDown({ text, more }) {
  return (
    <div className="arrowDown">
      <img src={arrowDown} alt="arrow to navigate down" />

      <Link
        activeClass="active"
        to={more}
        spy={true}
        smooth={true}
        duration={750}
      >
        <h2 className="more">{text}</h2>
      </Link>
    </div>
  )
}
