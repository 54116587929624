import React from 'react';

export function Timmi(){
    return(
        <h1 style={{
        fontSize:"42pt",
        fontFamily: "itc-american-typewriter,serif",
        fontWeight: 300,
        fontStyle: "light"}}>
        T I M M I
        </h1>
    )
}